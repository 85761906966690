import { h } from 'preact';
import { Router } from 'preact-router';
import AsyncRoute from 'preact-async-route';
import { useEffect, useState } from 'preact/hooks';
import { routes } from '../alias';
import {
    PartnerShiplandingPageRoute,
    partnerShipDashboardRoute,
    partnerShipLoginRoute,
} from '../alias/partnerShipRoutes';
import NotFound from '../routes/page-not-found';
import Redirect from './redirect';
import Spinner from "./spinner/spinner";
import MaliciousPopup from "./popup/malcious-file-popup";

import { AppContext } from '../app-context';
import { getLocalstorage } from '../utils';
import { OPEN_ROUTES, UNAUTHENTICATED_ROUTES } from '../constants';

function App() {
    const handleRoute = (e) => {
        const { current } = e || {};
        const { props: urlProps } = current || {};
        const { path } = urlProps || {};

        if (!getLocalstorage('token') && !UNAUTHENTICATED_ROUTES.includes(path) && !OPEN_ROUTES.includes(path)) {
            window.location.href = `${process.env.PREACT_APP_PARTNER_NAVIGATION_ENDPOINT}${partnerShipLoginRoute.path.substring(1)}`;
        }

        if (getLocalstorage('token') && UNAUTHENTICATED_ROUTES.includes(path)) {
            window.location.href = `${process.env.PREACT_APP_PARTNER_NAVIGATION_ENDPOINT}${partnerShipDashboardRoute.path.substring(1)}`;
        }
    };

    useEffect(() => {
        window.history.scrollRestoration = 'manual'
    }, []);


    return (
        <AppContext>
            <div id="app">
				<main>
                    <Router onChange={handleRoute}>
                        {(routes || []).map((childRoute) => {
                            return (
                                <AsyncRoute
                                    key={childRoute.path}
                                    path={childRoute.path}
                                    getComponent={
										!childRoute.showInMobile && window.innerWidth <= 768
											? () =>
													import(`./mobile-display`).then(
														(module) => module.default
													)
											: () =>
													import(`../routes/${childRoute.component}`).then(
														(module) => module.default
													)
									}
                                    loading={() => (
                                        <div className='loaderContainer'>
                                            <Spinner />
                                        </div>
                                    )}
                                />
                            );
                        })}
                        <Redirect
                            path='/'
                            replace={true}
                            to={`${PartnerShiplandingPageRoute.path}`}
                        />
                        <NotFound default />
                    </Router>
                </main>
                <MaliciousPopup />
            </div>
        </AppContext>
    );
}

export default App;
