export const partnerShipTermsConditionsRoute = {
    path: '/terms-and-conditions',
    component: 'terms-and-conditions',
    componentName: 'Terms And Conditons',
    theme: 'default',
    template: 'home',
    authenticated: true,
    showInMobile: true
};
export const partnerShipPrivacyPolicyRoute = {
    path: '/privacy-policy',
    component: 'privacy-policy',
    componentName: 'Privacy Policy',
    theme: 'default',
    template: 'home',
    authenticated: true,
    showInMobile: true
};
export const partnerShipDisclaimerRoute = {
    path: '/disclaimer',
    component: 'disclaimer',
    componentName: 'Disclaimer',
    theme: 'default',
    template: 'home',
    authenticated: true,
    showInMobile: true
};
export const partnerShipFaqRoute = {
    path: '/frequently-asked-questions',
    component: 'faq',
    componentName: 'Faq',
    theme: 'default',
    template: 'home',
    authenticated: true,
    showInMobile: true
};
export const partnerShipContactUsRoute = {
    path: '/contact-us',
    component: 'contact-us',
    componentName: 'Contact Us',
    theme: 'default',
    template: 'home',
    authenticated: true,
    showInMobile: true
};
export const partnerShipDashboardRoute = {
    path: '/dashboard',
    component: 'dashboard',
    componentName: 'Dashboard',
    theme: 'default',
    template: 'home',
    authenticated: true,
    showInMobile: true
};
export const partnerShipDetailedDashboardRoute = {
    path: '/detailed-dashboard',
    component: 'detailed-dashboard',
    componentName: 'Detailed Dashboard',
    theme: 'default',
    template: 'home',
    authenticated: true
};
export const partnerShipAccountSelectRoute = {
    path: '/account-select',
    component: 'account-select',
    componentName: 'Select Account Type',
    theme: 'default',
    template: 'home',
    authenticated: true,
    showInMobile: true
};
export const partnerShipSignupRoute = {
    path: '/signup',
    component: 'signup',
    componentName: 'Signup',
    theme: 'default',
    template: 'home',
    authenticated: true,
    showInMobile: true
};
export const partnerShipLoginRoute = {
    path: '/login',
    component: 'login',
    componentName: 'Login',
    theme: 'default',
    template: 'home',
    authenticated: true,
    showInMobile: true
};
export const partnerShipForgotPasswordRoute = {
    path: '/forgot-password',
    component: 'forgot-password',
    componentName: 'Forgot Password',
    theme: 'default',
    template: 'home',
    authenticated: true,
    showInMobile: true
};
export const partnerShipResetPasswordRoute = {
    path: '/reset-password',
    component: 'reset-password',
    componentName: 'Reset Password',
    theme: 'default',
    template: 'home',
    authenticated: true
};
export const partnerShipLoginOtpRoute = {
    path: '/login-otp',
    component: 'login-otp',
    componentName: 'Login Otp',
    theme: 'default',
    template: 'home',
    authenticated: true,
    showInMobile: true
};
export const partnerShipForgotOtpRoute = {
    path: '/forgot-otp',
    component: 'forgot-otp',
    componentName: 'Forgot Otp',
    theme: 'default',
    template: 'home',
    authenticated: true,
    showInMobile: true
};
export const panAadhaarSignupRoute = {
    path: '/verify-pan-aadhaar',
    component: 'pan-aadhaar',
    componentName: 'Verify Pan Aadhaar',
    theme: 'default',
    template: 'home',
    authenticated: true,
    showInMobile: true
};
export const digilockerProcessing = {
    path: '/digilocker-processing',
    component: 'digilocker-processing',
    componentName: 'Digilocker Processing',
    theme: 'default',
    template: 'home',
    authenticated: true,
    showInMobile: true
};
export const personalDetailsSignupRoute = {
    path: '/personal-details',
    component: 'personal-details',
    componentName: 'Personal Details',
    theme: 'default',
    template: 'home',
    authenticated: true,
    showInMobile: true
};
export const videoRegistrationSignupRoute = {
    path: '/video-registration',
    component: 'video-registration',
    componentName: 'Video Registration',
    theme: 'default',
    template: 'home',
    authenticated: true,
    showInMobile: true
};
export const verifyBankRoute = {
    path: '/verify-bank',
    component: 'verify-bank',
    componentName: 'Verify Bank',
    theme: 'default',
    template: 'home',
    authenticated: true,
    showInMobile: true
};
export const addBankRoute = {
    path: '/add-bank',
    component: 'add-bank',
    componentName: 'Add Bank',
    theme: 'default',
    template: 'home',
    authenticated: true,
    showInMobile: true
};
export const esignRoute = {
    path: '/esign',
    component: 'partner-esign',
    componentName: 'E Sign',
    theme: 'default',
    template: 'home',
    authenticated: true,
    showInMobile: true
};
export const eSignProcessing = {
    path: '/esign-processing',
    component: 'esign-processing',
    componentName: 'E Sign Processing',
    theme: 'default',
    template: 'home',
    authenticated: true,
    showInMobile: true
};
export const IndividualOfflineRegistrationRoute = {
    path: '/individual-registration',
    component: 'individual-offline-registration',
    componentName: 'Individual Offline Registration',
    theme: 'default',
    template: 'home',
    authenticated: true,
    showInMobile: true
};
export const corporateRegistrationRoute = {
    path: '/corprate-registration',
    component: 'corprate-registration',
    componentName: 'Corprate Registration',
    theme: 'default',
    template: 'home',
    authenticated: true,
    showInMobile: true
};
export const partnerRegistrationRoute = {
    path: '/partner-registration',
    component: 'partner-registration',
    componentName: 'Partner Registration',
    theme: 'default',
    template: 'home',
    authenticated: true,
    showInMobile: true
};
export const llpRegistrationRoute = {
    path: '/llp-registration',
    component: 'llp-registration',
    componentName: 'Partner LLP Registration',
    theme: 'default',
    template: 'home',
    authenticated: true,
    showInMobile: true
};
export const thankYouPageRoute = {
    path: '/thank-you',
    component: 'thank-you',
    componentName: 'Thank You',
    theme: 'default',
    template: 'home',
    authenticated: true,
    showInMobile: true
};
export const PartnerShiplandingPageRoute = {
    path: '/',
    component: 'landing',
    componentName: 'Landing',
    theme: 'default',
    template: 'home',
    authenticated: true,
    showInMobile: true
};
export const DMZPageRoute = {
    path: '/dmz-demo',
    component: 'dmz-demo',
    componentName: 'Dmz Demo',
    theme: 'default',
    template: 'home',
    authenticated: true
};
export const partnerShipRoutes = [
    partnerShipTermsConditionsRoute,
    partnerShipPrivacyPolicyRoute,
    partnerShipDisclaimerRoute,
    partnerShipFaqRoute,
    partnerShipContactUsRoute,
    partnerShipDashboardRoute,
    partnerShipDetailedDashboardRoute,
    partnerShipAccountSelectRoute,
    partnerShipSignupRoute,
    panAadhaarSignupRoute,
    partnerShipLoginRoute,
    partnerShipForgotPasswordRoute,
    partnerShipResetPasswordRoute,
    partnerShipLoginOtpRoute,
    partnerShipForgotOtpRoute,
    personalDetailsSignupRoute,
    verifyBankRoute,
    addBankRoute,
    esignRoute,
    corporateRegistrationRoute,
    partnerRegistrationRoute,
    llpRegistrationRoute,
    thankYouPageRoute,
    DMZPageRoute,
    PartnerShiplandingPageRoute,
    IndividualOfflineRegistrationRoute,
    digilockerProcessing,
    eSignProcessing,
    videoRegistrationSignupRoute
];
