import { commonRoutes } from "./commonRoutes";
import { inventoryManagement } from "./inventoryManagementRoutes";
import { partnerModuleRoute } from "./partnerModuleRoutes";
import { partnerProfileRoute } from "./partnerProfileRoutes";
import { partnerSgbRoute } from "./partnerSgbRoutes";
import { partnerShipRoutes } from "./partnerShipRoutes";

export const routes = [
  ...commonRoutes,
  ...partnerModuleRoute,
  ...partnerProfileRoute,
  ...partnerShipRoutes,
  ...inventoryManagement,
  ...partnerSgbRoute,
];
