export const product = {
  path: "/inventory/product",
  component: "inventory-management/product",
  componentName: "product",
  theme: "default",
  template: "user",
  authenticated: true,
};

export const inventoryManagement = [product];
