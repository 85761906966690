export const pageNotFoundRoute = {
    path: '/404',
    component: 'page-not-found',
    componentName: 'Page Not Found',
    theme: 'default',
    template: 'common',
};

export const serverErrorRoute = {
    path: '/server-error',
    component: 'server-error',
    componentName: 'Server Error',
    theme: 'default',
    template: 'common',
};

export const comingSoonRoute = {
    path: '/coming-soon',
    component: 'coming-soon',
    componentName: 'Comming Soon',
    theme: 'default',
    template: 'common',
};

export const commonRoutes = [
    pageNotFoundRoute,
    serverErrorRoute,
    comingSoonRoute
]