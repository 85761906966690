export const UNAUTHENTICATED_ROUTES = [
    '/',                             //landing page
    '/login',
    '/forgot-password',
    '/login-otp',
    '/forgot-otp',
    '/account-select',
    '/signup'
];

export const OPEN_ROUTES = [
    '/frequently-asked-questions',
    '/disclaimer',
    '/privacy-policy',
    '/terms-and-conditions',
    '/contact-us',
];

// If any routing requires a base URL other than the partner's default
export const ROUTE_ENDPOINTS = {
    billing: process.env.PREACT_APP_BILLING_NAVIGATION_ENDPOINT,
    bond_directory: process.env.PREACT_APP_NAVIGATION_ENDPOINT,
    learning_module: process.env.PREACT_APP_NAVIGATION_ENDPOINT
};