import { h, Component } from 'preact';
import style from './spinner.scss';

export default class Spinner extends Component {
  componentWillUnmount() {}

  render(props) {
    return (
      <div className='text-center' style="margin-top: 15%; margin-bottom: 15%">
        <img src='https://assets.indiabonds.com/react/ib-portfolio/portfolio-loader-v2.gif' style="width: 100px;"/>
      </div>
    );
  }
}
