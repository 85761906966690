export const sgbLandingRoute = {
  path: "/sgb/landing",
  component: "partner-sgb/landing",
  componentName: "Sgb",
  theme: "sgb",
  template: "home",
  authenticated: true,
};
export const sgbOrderDetailsRoute = {
  path: "/sgb/order-details",
  component: "partner-sgb/order-details",
  componentName: "Sgb",
  theme: "sgb",
  template: "home",
  authenticated: true,
};
export const sgbMakePaymentRoute = {
  path: "/sgb/make-payment",
  component: "partner-sgb/make-payment",
  componentName: "Sgb",
  theme: "sgb",
  template: "home",
  authenticated: true,
};
export const sgbOrderReceivedRoute = {
  path: "/sgb/order-received",
  component: "partner-sgb/order-received",
  componentName: "Sgb",
  theme: "sgb",
  template: "home",
  authenticated: true,
};

export const partnerSgbRoute = [sgbLandingRoute, sgbOrderDetailsRoute, sgbMakePaymentRoute, sgbOrderReceivedRoute];
