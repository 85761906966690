export const createRoute = {
  path: "/partner/create/:createType",
  pathWithoutSlug: "/partner/create",
  component: "partner-module/create",
  componentName: "create",
  theme: "default",
  template: "user",
  authenticated: true,
};
export const editDashboardRoute = {
  path: "/partner/editdashboard/:activeTab?",
  pathWithoutSlug: "/partner/editdashboard",
  component: "partner-module/editdashboard",
  componentName: "editdashboard",
  theme: "default",
  template: "user",
  authenticated: true,
};
export const editBranchDepartmenmtRoute = {
  path: "/partner/editbranchdepartment",
  component: "partner-module/editbranch-department",
  componentName: "editbranch-department",
  theme: "default",
  template: "user",
  authenticated: true,
};
export const editEmployeeRoute = {
  path: "/partner/editemployee/:partnerId",
  pathWithoutSlug: "/partner/editemployee",
  component: "partner-module/edit-employee",
  componentName: "edit-employee",
  theme: "default",
  template: "user",
  authenticated: true,
};
export const deactiveBranchDepartmentRoute = {
  path: "/partner/deactivate/:branchDeptName",
  pathWithoutSlug: "/partner/deactivate",
  component: "partner-module/deactivate-branch-department",
  componentName: "deactivate-branch-department",
  theme: "default",
  template: "user",
  authenticated: true,
};
export const deactiveEmployeeRoute = {
  path: "/partner/deactivateemployee/:employeeName/:partnerId",
  pathWithoutSlug: "/partner/deactivateemployee",
  component: "partner-module/deactivate-employee",
  componentName: "deactivate-employee",
  theme: "default",
  template: "user",
  authenticated: true,
};

export const partnerModuleRoute = [
  createRoute,
  editDashboardRoute,
  editBranchDepartmenmtRoute,
  editEmployeeRoute,
  deactiveBranchDepartmentRoute,
  deactiveEmployeeRoute,
];
