// @flow
/** @jsx h */
import { h, Component } from 'preact';
import { route } from 'preact-router';
import Spinner from '../spinner/spinner';

class Redirect extends Component {
  componentDidMount() {
    const { to, replace } = this.props || {};
    route(to || '/', replace || false);
  }
  render() {
    return (
      <div className='loaderContainer'>
        <Spinner />
      </div>
    );
  }
}

export default Redirect;
