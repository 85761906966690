import { useState, createContext, useContext } from 'react';

const Context = createContext({
    userObj: {},
});

const DispatchContext = createContext({});

export const AppContext = ({ children }) => {
    const [userObj, setUserObj] = useState({});
    const [profileData, setProfileData] = useState({});

    const storeValue = {
        userObj,
        profileData
    };

    const dispatchStoreValue = {
        setUserObj,
        setProfileData
    };
    
    window.appStore = storeValue;

    return (
        <Context.Provider value={{ ...storeValue }}>
            <DispatchContext.Provider value={{ ...dispatchStoreValue }}>
                {children}
            </DispatchContext.Provider>
        </Context.Provider>
    );
};
  
export const useAppContext = () => useContext(Context)

export const useDispatchAppContext = () => useContext(DispatchContext)
