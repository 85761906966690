import { Component, h } from 'preact';
import style from './style.scss';

class MaliciousPopup extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount(){
    }

    render() {
        return (
            <div class="modal fade" id="maliciousFilePopup" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
                <div className={`modal-dialog ${style.expressConsentPopup}`} role="document">
                    <div className={`modal-content ${style.expressConsentPopupContent}`}>
                        <div className={`modal-header ${style.popupHeader}`}>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">
                                    <img src="https://assets.indiabonds.com/react/partner-program/cross.png" />
                                </span>
                            </button>
                            <div className={`text-center ${style.popupHeaderIconContainer}`}>
                                <img src="https://assets.indiabonds.com/react/partner-program/malicious-file-icon.png" />
                            </div>
                        </div>
                        <div class="modal-body">
                            <div className={`text-center ${style.popupTitle}`}>
                                Malicious File Detected
                            </div>
                            <div className={`text-center ${style.popupSubHeader}`}>
                                <div>Uploaded File has been scanned by system and found to be</div> 
                                <div>Malicious and has been blocked. Please upload a virus / malicious free file.</div>
                            </div>
                        </div>
                        <div className={`modal-footer  ${style.popupFooter}`}>
                            <button type="button" class="btn primary-btn font_13">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default MaliciousPopup;
