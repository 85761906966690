export const individualUserRoute = {
  path: "/profile/individual",
  component: "partner-profile/individiual",
  componentName: "individiual",
  theme: "default",
  template: "user",
  authenticated: true,
};
export const corporateUserRoute = {
  path: "/profile/corporate",
  component: "partner-profile/corporate",
  componentName: "corporate",
  theme: "default",
  template: "user",
  authenticated: true,
};

export const partnerProfileRoute = [individualUserRoute, corporateUserRoute];
